import axios from "axios";
import { useQuery } from "react-query";
import { store } from "src";

const fetchCurrentUser = async () => {
  const baseUrl = process.env.REACT_APP_LARAVEL_URL;

  return await axios
    .get(`${baseUrl}/user/my/profile`, {
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ${localStorage.getItem("sis-token")}`,
      },
    })
    .then((response) => {
      store.dispatch({
        type: "LOGIN",
        payload: response.data.data,
      });
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const CURRENT_USER_QUERY_KEY = "getCurrentUser";

const useCurrentUser = (params = {}, options = {}) => {
  const queryKey = [CURRENT_USER_QUERY_KEY, params];
  useQuery(queryKey, fetchCurrentUser, {
    ...options,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

export default useCurrentUser;
