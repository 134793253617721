/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { HashRouter, Switch } from "react-router-dom";
import "./scss/style.scss";
import GuestRoute from "./auth/GuestRoute";
import ProtectedRoute from "./auth/ProtectedRoute";
import useCurrentUser from "./services/useCurrentUser";
import useRefreshTokenAccurate from "./services/useRefreshTokenAccurate";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Customer = React.lazy(() => import("./views/authentication/customer/Customer"));
const Login = React.lazy(() => import("./views/authentication/Login"));
const ForgotPassword = React.lazy(() =>
  import("./views/authentication/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./views/authentication/ResetPassword")
);

const App = () => {
  useCurrentUser(
    {},
    {
      enabled: !!localStorage.getItem("sis-token"),
    }
  );

  useRefreshTokenAccurate(
    {},
    {
      enabled: !!localStorage.getItem("sis-token"),
    }
  );

  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <GuestRoute
            exact
            path="/customer/:code"
            name="Customer Page"
            component={Customer}
          />
          <GuestRoute exact path="/login" name="Login Page" component={Login} />
          <GuestRoute
            exact
            path="/forgot-password"
            name="Forgot Password"
            component={ForgotPassword}
          />
          <GuestRoute
            exact
            path="/reset-password/:id"
            name="Reset Password"
            component={ResetPassword}
          />
          <ProtectedRoute path="/" name="Home" component={TheLayout} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
};

export default App;
