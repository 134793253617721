import axios from "axios";
import { useQuery } from "react-query";

const fetchRefreshTokenAccurate = async () => {
  const baseUrl = process.env.REACT_APP_LARAVEL_URL;

  return await axios
    .get(`${baseUrl}/accurate/koneksi/refresh-token`, {
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ${localStorage.getItem("sis-token")}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const CURRENT_USER_QUERY_KEY = "refreshTokenAccurate";

const useRefreshTokenAccurate = (params = {}, options = {}) => {
  const queryKey = [CURRENT_USER_QUERY_KEY, params];
  useQuery(queryKey, fetchRefreshTokenAccurate, {
    ...options,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchInterval: 900000,
  });
};

export default useRefreshTokenAccurate;
